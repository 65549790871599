import {Component} from '@angular/core';
import {LocalNotifications, ScheduleOptions} from "@capacitor/local-notifications";
import queryString from 'query-string';
import {QRCodeData} from "@models/connectors";
import {Platform} from "@ionic/angular";
import {LocalNotificationsService} from "./services/local-notifications.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private notify: LocalNotificationsService) {
  }

  async ngOnInit(): Promise<void> {
    this.checkUrl();
    LocalNotifications.checkPermissions().then(async (result) => {
      console.log(result)
      if (result.display != "granted") {
        await LocalNotifications.requestPermissions()
      }
    })
  }

  private checkUrl() {
    const parsed = queryString.parse(location.search);
    const qr: QRCodeData = Object.assign(parsed)
    if (qr.connector) {
      localStorage.setItem('connector', qr.connector)
    }
    if (qr.station) {
      localStorage.setItem('station', qr.station)
    }

  }
}
