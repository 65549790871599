import {ProtectedResourceScopes} from "@azure/msal-angular";
import {InteractionType, LogLevel} from "@azure/msal-browser";

export const environment = {
  production: false,
  apiEndpoint: 'https://dev-api-01.atompower.com/ev/v1',
  azureB2C: {
    auth: {
      clientId: "12ac412f-d042-4794-9ba7-6ceb914ebe89",
      authority: "https://atompowerdev.b2clogin.com/atompowerdev.onmicrosoft.com/B2C_1_signin",
      redirectUri: "/",
      knownAuthorities: ['atompowerdev.b2clogin.com'],
      postLogoutRedirectUri: "/",
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      allowNativeBroker: false,
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    },
    guardConfig: {
      interactionType: InteractionType.Redirect,
      loginFailedRoute: '/'
    },
    interceptorConfig: {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map<string, Array<string | ProtectedResourceScopes> | null>().set(
        'https://dev-api-01.atompower.com/*', ['https://atompowerdev.onmicrosoft.com/de76ec9c-eb58-420b-ba0a-5af49cb9af59/Driver.User']
      )
    }
  },
  featureFlags: {
    fees: true,
    accessGroupSchedules: true,
  }
};
