import {NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MsalInterceptor, MsalModule, MsalRedirectComponent} from '@azure/msal-angular';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {InteractionType, PublicClientApplication} from "@azure/msal-browser";
import {environment} from "@environments/environment";
import {BackButtonDisableModule} from "angular-disable-browser-back-button";


@NgModule({

  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({swipeBackEnabled: false, experimentalCloseWatcher: true}),
    BackButtonDisableModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: environment.azureB2C.auth,
        cache: environment.azureB2C.cache,
        system: environment.azureB2C.system
      }),
      {
        interactionType: InteractionType.Redirect,
        loginFailedRoute: '/'
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: environment.azureB2C.interceptorConfig.protectedResourceMap
      }
    ),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      scope: '/',
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy, useClass: IonicRouteStrategy
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
}
