import {Injectable} from '@angular/core';
import {LocalNotifications} from "@capacitor/local-notifications";

@Injectable({
    providedIn: 'root'
})
export class LocalNotificationsService {

    constructor() {
    }

    async notifyServiceWorker(title: string, body: string, options?: NotificationOptions) {
        await Notification.requestPermission(function (result) {
            if (result === 'granted') {
                navigator.serviceWorker.ready.then(function (registration) {
                    registration.showNotification(title, {
                        body,
                        icon: "assets/icon/favicon.png",
                        //image: "assets/icon/favicon.png",
                        badge: "assets/icon/favicon.png",
                        ...options
                    });
                });
            }
        });
    }

    async notifyNative() {
        await LocalNotifications.schedule({
            notifications: [
                {
                    title: 'Charging in Progress',
                    body: 'Vehicle is charging.',
                    id: 1,
                    schedule: {at: new Date(Date.now() + 1000 * 15)},
                    sound: null,
                    attachments: null,
                    actionTypeId: '',
                    extra: null,
                },
            ],
        })
    }

}
